import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import logo  from './assets/icons/aa.png';
import { ReactComponent as IconInstagram } from './assets/icons/instagram.svg';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href="."><img src={logo} alt="Logo" width={250} /></a>
          </div>
          <div className="social">
            <a href="https://facebook.com/profile.php?id=100068140765742" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://www.instagram.com/_yun_shop" title="Instagram" target="_blank" rel="noopener noreferrer">
              <IconInstagram className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Siete pronti ?! 😋</h1>
            <p>Grossa bomba in arrivo prossimamente 💥</p>
          </div>
          <a href="https://yunshop.it">
            <div className="cta">Vai Allo Shop 🛒</div>
          </a>
        </div>
        <div className="footer">
          <span>made with ❤️ <a className="underlined" href="https://federicomengascini.com" target="_blank" rel="noopener noreferrer">Federico</a> </span>
        </div>
      </div>
    );
  }
}

export default App;